<script lang="ts">
  import type { PageContext } from '$lib/definitions'
  import { getContext, onMount } from 'svelte'
  import MarketSummaryCard from '$lib/components/MarketSummaryCard.svelte'
  import ProfileCard from '$lib/components/ProfileCard.svelte'
  import AccountBalanceCard from '$lib/components/AccountBalanceCard.svelte'
  import { portal } from 'svelte-portal'

  const { setPage } = getContext<PageContext>('pageContext')

  onMount(() => {
    setPage({
      navbarTitle: 'LFX',
    })
  })
</script>

<svelte:head>
  <title>LFX</title>
</svelte:head>

<header class="flex flex-col px-4 py-2 md:flex-row md:items-end md:justify-between md:space-x-4" use:portal={'#navbar-portal'}>
  <ProfileCard class="order-1 mt-2 hidden md:order-1 md:mt-0 md:flex" />
  <AccountBalanceCard class="md:order-2 md:col-span-3 md:block" />
</header>

<div class="m-4 space-y-4">
  <ProfileCard class="md:hidden" />

  <div class="space-y-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0">
    <MarketSummaryCard ccy="USD" />
    <MarketSummaryCard ccy="THB" />
    <MarketSummaryCard ccy="CNY" />
  </div>
</div>
