import axios, { type AxiosError, type AxiosInstance, type InternalAxiosRequestConfig, type AxiosResponse } from 'axios'

const _coreAxios: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_CORE_URL,
})

_coreAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    console.log('HTTP_REQUEST', {
      baseUrl: config.baseURL,
      url: config.url,
      headers: config.headers,
      data: config.data ?? undefined,
    })

    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

// ADD A 401 RESPONSE INTERCEPTOR
_coreAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    console.log('HTTP_RESPONSE', response.data)
    return response
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

export const coreAxios = _coreAxios
