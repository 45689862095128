<script lang="ts">
  import { formatNumber } from '$lib/utils'
  import { historyBook } from '$lib/store'
  import dayjs from 'dayjs'

  export let ccy: string = 'USD'
</script>

<section class="overflow-y-scroll text-sm">
  <table class="w-full">
    <thead>
      <tr>
        <th class="p-1 text-left text-xs">ເວລາ</th>
        <th class="p-1 text-right text-xs">ລາຄາ LAK</th>
        <th class="p-1 text-right text-xs">ຈຳນວນ {ccy}</th>
      </tr>
    </thead>
    <tbody>
      {#if $historyBook !== null}
        {#each $historyBook[ccy] ?? [] as item}
          <tr>
            <td class="p-1 text-xs">{dayjs(item.txtime).format('DD/MM/YYYY HH:mm:ss')}</td>
            <td class="p-1 text-right text-xs">{formatNumber(item.price)}</td>
            <td class="p-1 text-right text-xs">{formatNumber(item.amount)}</td>
          </tr>
        {/each}
      {/if}
    </tbody>
  </table>
</section>
