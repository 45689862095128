<script lang="ts">
  import { currentPage, isKeyboardShown } from '$lib/store'
  import { AppBar, AppShell } from '@skeletonlabs/skeleton'
  import Icon from '@iconify/svelte'
  import { setContext } from 'svelte'
  import type { PageContext } from '$lib/definitions'
  import { showPage } from '$lib/page'
  import { closeApp } from '$lib/utils/app'

  export let name
  export let component

  let navbarTitle: string = ''
  let showAccountBalance: boolean = true

  setContext<PageContext>('pageContext', {
    setPage: (params) => {
      navbarTitle = params.navbarTitle ?? ''
      showAccountBalance = params.showAccountBalance ?? true
    },
  })
</script>

{#if name === $currentPage.name}
  <AppShell>
    <svelte:fragment slot="header">
      <div class="bg-appbar backdrop-blur-2xl">
        {#if !['login', 'register'].includes(name)}
          <AppBar padding="py-0 px-4" shadow="shadow-none" spacing="" background="bg-transparent" class="h-14 justify-center {$isKeyboardShown ? 'hidden' : ''}">
            <svelte:fragment slot="lead">
              <button type="button" on:click={closeApp}>
                <Icon icon="mdi:close" class="size-6 text-white" />

                <!--  {#if ['history/details'].includes(name)}-->
                <!--    <Icon icon="mdi:arrow-left" class="w-7 h-7 text-white"/>-->
                <!--  {:else}-->
                <!--    <Icon icon="mdi:menu" class="size-7 text-white"/>-->
                <!--  {/if}-->
              </button>
            </svelte:fragment>

            <div class="flex w-full items-center justify-between space-x-4">
              <div class="flex-1 text-center font-semibold text-white">{navbarTitle}</div>
              <div id="navbar-context-portal"></div>
            </div>

            <svelte:fragment slot="trail">
              <div id="navbar-context-icon-portal"></div>
            </svelte:fragment>
          </AppBar>
        {/if}

        <div id="navbar-portal" class="sticky left-0 right-0 top-0 z-50 -my-2 -mt-3"></div>
      </div>
    </svelte:fragment>

    <svelte:component this={component} />

    <svelte:fragment slot="footer">
      {#if !['login', 'register', 'landing'].includes(name)}
        <nav class="navbar {$isKeyboardShown ? '!hidden' : ''}">
          <button type="button" class="navbar-item" class:selected={$currentPage.name === 'home'} on:click={() => showPage('home')}>
            <Icon icon="ep:menu" class="size-7" />
            <span>ໜ້າຫຼັກ</span>
          </button>

          <button type="button" class="navbar-item" class:selected={$currentPage.name === 'market'} on:click={() => showPage('market')}>
            <Icon icon="mdi:exchange" class="size-7" />
            <span>ແລກປ່ຽນ</span>
          </button>

          <button type="button" class="navbar-item" class:selected={$currentPage.name === 'history'} on:click={() => showPage('history')}>
            <Icon icon="mdi:clipboard-text-clock" class="size-7" />
            <span>ປະຫວັດ</span>
          </button>

          <!--          <button type="button" class="navbar-item" class:selected={$currentPage.name === 'deposit'} on:click={() => showPage('deposit')}>-->
          <!--            <Icon icon="mdi:briefcase-download" class="size-7" />-->
          <!--            <span>ຝາກເງິນ</span>-->
          <!--          </button>-->

          <!--          <button type="button" class="navbar-item" class:selected={$currentPage.name === 'withdraw'} on:click={() => showPage('withdraw')}>-->
          <!--            <Icon icon="mdi:briefcase-upload" class="size-7" />-->
          <!--            <span>ຖອນເງິນ</span>-->
          <!--          </button>-->

          <!--<button type="button" class="navbar-item" class:selected={$currentPage.name === 'notify'}-->
          <!--        on:click={() => showPage('notify')}>-->
          <!--  <Icon icon="mdi:bell" class="size-7" />-->
          <!--  <span>ແຈ້ງເຕືອນ</span>-->
          <!--</button>-->

          <!--<button type="button" class="navbar-item" class:selected={$currentPage.name === 'swap'} on:click={() => showPage('swap')}>-->
          <!--  <Icon icon="mdi:swap-vertical-bold" class="size-7" />-->
          <!--  <span>ແລກປ່ຽນ<br/>ວົງເງິນສູງ</span>-->
          <!--</button>-->

          <button type="button" class="navbar-item" class:selected={$currentPage.name === 'transfer'} on:click={() => showPage('transfer')}>
            <Icon icon="mdi:money" class="size-7" />
            <span>ຝາກ/ຖອນ</span>
          </button>

          <button type="button" class="navbar-item" class:selected={$currentPage.name === 'user'} on:click={() => showPage('user')}>
            <Icon icon="mdi:account" class="size-7" />
            <span>ບັນຊີ</span>
          </button>
        </nav>
      {/if}
    </svelte:fragment>
  </AppShell>
{/if}

<style lang="scss">
  .navbar {
    @apply flex items-start space-x-1 bg-main-black p-2;
  }

  .navbar-item {
    @apply flex flex-1 flex-col items-center justify-center rounded-xl p-1;

    &.selected {
      background: rgb(35, 63, 34);
      background: linear-gradient(0deg, lighten(#0e230e, 8%) 0%, lighten(rgba(35, 63, 34, 1), 8%) 80%, lighten(rgba(37, 73, 36, 1), 8%) 100%);
    }

    span {
      @apply block text-xs;
    }
  }

  .bg-appbar {
    background: rgb(6, 15, 6);
    background: linear-gradient(0deg, rgba(6, 15, 6, 1) 0%, rgba(35, 63, 34, 1) 80%, rgba(37, 73, 36, 1) 100%);
  }
</style>
