import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'
import type { OrderBook, Market, Order, InitData, PageState, Portfolio, AuthData, HistoryBook, VolumeBook } from '$lib/definitions'

export const backstack = writable<PageState[]>([])
export const currentPage = writable<PageState>({})

export const isLoading: Writable<boolean> = writable(false)

export const isKeyboardShown: Writable<boolean> = writable(false)

export const orderBook: Writable<OrderBook> = writable({})

export const market: Writable<Market | null> = writable(null)

export const historyBook: Writable<HistoryBook | null> = writable(null)

export const volumeBook: Writable<VolumeBook | null> = writable(null)

export const initData: Writable<InitData | null> = writable(null)

export const orders: Writable<Order[]> = writable([])

export const faceVerifiedToken: Writable<string | null> = writable(null)
export const biometric: Writable<string | null> = writable(null)

export const isAuthenticated: Writable<boolean> = writable(false)

export const portfolio = writable<Portfolio | null>(null)

export const authData: Writable<AuthData | null> = writable(null)

export const publicKey: Writable<string | null> = writable(null)

export const privateKey: Writable<string | null> = writable(null)

export const isLoadingBalance: Writable<boolean> = writable(false)
