<script lang="ts">
  import Deposit from '$lib/components/Deposit.svelte'
  import Withdraw from '$lib/components/Withdraw.svelte'
  import { getContext, onMount } from 'svelte'
  import type { PageContext } from '$lib/definitions'
  import AccountBalanceCard from '$lib/components/AccountBalanceCard.svelte'
  import { portal } from 'svelte-portal'

  const { setPage } = getContext<PageContext>('pageContext')

  let tab: string = 'deposit'

  onMount(() => {
    setPage({
      navbarTitle: 'ຝາກ / ຖອນ',
    })
  })
</script>

<svelte:head>
  <title>ຝາກ / ຖອນ</title>
</svelte:head>

<header class="px-4 py-2 md:mx-auto md:w-max" use:portal={'#navbar-portal'}>
  <AccountBalanceCard />
</header>

<header class="m-4 flex items-center space-x-1">
  <button type="button" class="flex w-full flex-1 items-center border-b-4 p-2 text-white {tab === 'deposit' ? 'border-primary' : 'border-gray-600'}" on:click={() => (tab = 'deposit')}>
    <span class="flex-1 font-bold">ຝາກ</span>
  </button>

  <button type="button" class="flex w-full flex-1 items-center border-b-4 p-2 text-white {tab === 'withdraw' ? 'border-primary' : 'border-gray-600'}" on:click={() => (tab = 'withdraw')}>
    <span class="flex-1 font-bold">ຖອນ</span>
  </button>
</header>

<div class="m-4 grid grid-cols-1 gap-4 md:grid-cols-2">
  <Deposit class={tab === 'deposit' ? 'block' : 'hidden md:block'} />
  <Withdraw class={tab === 'withdraw' ? 'block' : 'hidden md:block'} />
</div>
