<script lang="ts">
  import _ from 'lodash'
  import ActiveOrdersList from '$lib/components/ActiveOrdersList.svelte'
  import Icon from '@iconify/svelte'
  import { orders } from '$lib/store'
  import { handleAxiosError } from '$lib/utils/alert'
  import { sendCoreRequest } from '$lib/utils/app'
  import type { Order } from '$lib/definitions'

  export let ccy = ''

  let status: 'ACTIVE' | 'DONE' = 'ACTIVE'
  let isLoadingOrders: boolean = false

  $: showActiveOrders = ccy !== '' ? (_.filter($orders, { ccy }) as Order[]) : $orders

  export async function getActiveOrders() {
    try {
      isLoadingOrders = true

      const { data } = await sendCoreRequest('/user/getactiveorders')
      $orders = data

      isLoadingOrders = false
    } catch (error) {
      isLoadingOrders = false
      await handleAxiosError(error)
    }
  }
</script>

<section class="m-4 space-y-4">
  <nav class="flex items-center space-x-1">
    <button type="button" class="flex w-full flex-1 items-center border-b-4 p-2 text-white {status === 'ACTIVE' ? 'border-primary' : 'border-gray-600'}" on:click={() => (status = 'ACTIVE')}>
      <span class="flex-1 font-bold">ລາຍການລໍຖ້າ</span>
    </button>

    <button type="button" class="flex w-full flex-1 items-center border-b-4 p-2 text-white {status === 'DONE' ? 'border-primary' : 'border-gray-600'}" on:click={() => (status = 'DONE')}>
      <span class="flex-1 font-bold">ລາຍການສຳເລັດ</span>
    </button>
  </nav>

  <header class="flex items-center justify-center">
    <button type="button" class="variant-filled-primary btn flex items-center space-x-1 p-1.5" on:click={getActiveOrders}>
      <Icon icon="mdi:refresh" class="h-4 w-4" />
      <div class="text-xs">ອັບເດດ</div>
    </button>
  </header>

  {#if isLoadingOrders}
    <aside class="space-y-3">
      {#each Array(5) as _}
        <div class="card">
          <div class="flex items-center space-x-3 p-3">
            <div class="placeholder-circle w-16 animate-pulse"></div>
            <div class="flex-1 space-y-3">
              <div class="placeholder animate-pulse"></div>
              <div class="placeholder animate-pulse"></div>
            </div>
          </div>
        </div>
      {/each}
    </aside>
  {/if}

  {#if !isLoadingOrders && showActiveOrders.length === 0}
    <div class="card p-4 text-center text-sm">ບໍ່ມີລາຍການ</div>
  {/if}

  {#if !isLoadingOrders && showActiveOrders.length > 0}
    <div class="space-y-3" class:hidden={status !== 'ACTIVE'}>
      <ActiveOrdersList orders={showActiveOrders.filter((order) => order.status === 'ACTIVE')} on:cancel={getActiveOrders} />
    </div>

    <div class="space-y-3" class:hidden={status !== 'DONE'}>
      <ActiveOrdersList orders={showActiveOrders.filter((order) => order.status === 'DONE')} on:cancel={getActiveOrders} />
    </div>
  {/if}
</section>
