<script lang="ts">
  import _ from 'lodash'
  import { orderBook } from '$lib/store'
  import type { OrderBookCCY, OrderBookCCYDetail } from '$lib/definitions'
  import { formatNumber } from '$lib/utils'

  export let ccy: string = 'USD'

  let buyItems: OrderBookCCYDetail[] = []
  let sellItems: OrderBookCCYDetail[] = []

  $: {
    // @ts-ignore
    const book: OrderBookCCY | undefined = $orderBook[ccy]

    if (book !== undefined) {
      buyItems = _.chain(book.BUY)
        .filter((item) => item.amount > 0)
        .orderBy('price', 'desc')
        .value()

      sellItems = _.chain(book.SELL)
        .filter((item) => item.amount > 0)
        .orderBy('price', 'asc')
        .value()
    } else {
      buyItems = []
      sellItems = []
    }
  }
</script>

<section class="grid grid-cols-2 gap-4 overflow-y-scroll">
  <table class="h-max text-xs font-medium">
    <tr>
      <td colspan="2" class="py-1 text-center text-sm font-bold">ຕ້ອງການຊື້</td>
    </tr>
    <tr>
      <td class="text-xs text-surface-100">
        <span>ລາຄາ</span>
        <span class="text-xs">(LAK)</span>
      </td>
      <td class="text-right text-xs text-surface-100">
        <span>ຈຳນວນ</span>
        <span class="text-xs">({ccy})</span>
      </td>
    </tr>

    {#each buyItems as item}
      <tr>
        <td class="text-buy">{formatNumber(item.price, 2)}</td>
        <td class="text-right">{formatNumber(item.amount, 2)}</td>
      </tr>
    {/each}
  </table>

  <table class="h-max text-xs font-medium">
    <tr>
      <td colspan="2" class="py-1 text-center text-sm font-bold">ຕ້ອງການຂາຍ</td>
    </tr>
    <tr>
      <td class="text-xs text-surface-100">
        <span>ລາຄາ</span>
        <span class="text-xs">(LAK)</span>
      </td>
      <td class="text-right text-xs text-surface-100">
        <span>ຈຳນວນ</span>
        <span class="text-xs">({ccy})</span>
      </td>
    </tr>

    {#each sellItems as item}
      <tr>
        <td class="text-sell">{formatNumber(item.price, 2)}</td>
        <td class="text-right">{formatNumber(item.amount, 2)}</td>
      </tr>
    {/each}
  </table>
</section>
