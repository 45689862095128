<script lang="ts">
  import type { PortfolioAccount } from '$lib/definitions'
  import { portfolio } from '$lib/store'
  import { cancelLoading, formatNumber, showLoading } from '$lib/utils'
  import InputMask from '$lib/components/InputMask.svelte'
  import { handleAxiosError, showAlert, Swal } from '$lib/utils/alert'
  import { sendCoreRequest, updateAccountBalance } from '$lib/utils/app'
  import { onMount } from 'svelte'

  const amountMaskOption = {
    mask: Number,
    scale: 2,
    thousandsSeparator: ',',
    padFractionalZeros: false,
    normalizeZeros: true,
    radix: '.',
    mapToRadix: ['.', ','],
    min: 0,
    max: 1000000000000,
  }

  let amount: number = 0
  let account: PortfolioAccount

  onMount(() => {
    if ($portfolio) {
      account = $portfolio.accounts[0]
    }
  })

  async function withdraw() {
    if (amount <= 0) {
      await showAlert('warning', 'ກະລຸນາປ້ອນຈຳນວນເງິນ')
      return
    }

    const swalResult = await Swal.fire({
      icon: 'question',
      text: `ທ່ານຕ້ອງການຖອນເງິນຈຳນວນ ${formatNumber(amount, 2)} ${account.ccy} ອອກບັນຊີ ${account.linkaccount} ແທ້ບໍ່?`,
      showCancelButton: true,
      confirmButtonText: 'ຢືນຢັນ',
    })

    if (!swalResult.isConfirmed) return

    try {
      showLoading()

      await sendCoreRequest('/user/withdraw', {
        amount: amount,
        ccy: account.ccy,
      })

      await updateAccountBalance()
      amount = 0

      cancelLoading()

      await showAlert('success', 'ຖອນເງິນສຳເລັດ')
    } catch (error) {
      await handleAxiosError(error)
    }
  }
</script>

<div class="card space-y-4 p-4 {$$restProps.class}">
  {#if $portfolio}
    <div class="flex items-center justify-center space-x-2">
      {#each $portfolio.accounts as _account}
        <button
          type="button"
          class="rounded-lg border border-gray-400 px-4 py-2 text-center text-sm {account?.ccy === _account.ccy ? 'border-white bg-green-700' : ''}"
          on:click={() => (account = _account)}>
          {_account.ccy}
        </button>
      {/each}
    </div>
  {/if}

  <div class="space-y-1">
    <div class="text-center text-sm">ປ້ອນຈຳນວນ</div>
    <InputMask
      class="input text-center text-gray-600"
      inputmode="decimal"
      autocapitalize="off"
      autocomplete="off"
      spellcheck="false"
      value={amount}
      option={amountMaskOption}
      on:input={({ detail }) => (amount = detail)} />
  </div>

  <button type="button" class="variant-filled-error btn w-full" on:click={withdraw}>ຖອນເງິນ</button>
</div>
