import SweerAlert2 from 'sweetalert2'
import type { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2'
import { AxiosError } from 'axios'
import { cancelLoading } from '$lib/utils'
import { closeApp } from '$lib/utils/app'

export const Swal = SweerAlert2.mixin({
  customClass: {
    confirmButton: 'btn variant-filled-primary mx-2',
    cancelButton: 'btn variant-filled-surface mx-2',
  },
  buttonsStyling: false,
  confirmButtonText: 'ຕົກລົງ',
  cancelButtonText: 'ຍົກເລີກ',
  reverseButtons: true,
})

export async function showAlert(icon: SweetAlertIcon, text: string, params: SweetAlertOptions | undefined = undefined): Promise<SweetAlertResult> {
  return Swal.fire({
    ...params,
    icon,
    text,
  })
}

export async function handleAxiosError(error: unknown): Promise<void> {
  console.error(error)
  cancelLoading()

  if (error instanceof AxiosError) {
    if (error.response) {
      if (error.response.status === 401) {
        await showAlert('error', 'ທ່ານໃຊ້ເວລາດົນເກີນໄປ ກະລຸນາເຂົ້າສູ່ລະບົບໃໝ່')
        closeApp()
        return
      }

      await showAlert('error', error.response.data.message)
      return
    } else if (error.request) {
      await showAlert('error', 'No response from server')
      return
    }
  }

  await showAlert('error', 'Please try again later')
}
