<script lang="ts">
  import Icon from '@iconify/svelte'
  import _ from 'lodash'
  import { getModalStore, ProgressBar } from '@skeletonlabs/skeleton'
  import dayjs from 'dayjs'
  import { cancelLoading, formatNumber, showLoading } from '$lib/utils'
  import { createEventDispatcher } from 'svelte'
  import type { Order } from '$lib/definitions'
  import ExchangeDetailModal from '$lib/components/ExchangeDetailModal.svelte'
  import { handleAxiosError, Swal } from '$lib/utils/alert'
  import { sendCoreRequest } from '$lib/utils/app'

  export let orders: Order[] = []

  const modalStore = getModalStore()
  const dispatch = createEventDispatcher()

  async function cancelOrder(order: Order) {
    const { isConfirmed } = await Swal.fire({
      icon: 'question',
      text: 'ທ່ານຕ້ອງການຍົກເລີກລາຍການແທ້ບໍ່?',
      confirmButtonText: 'ຢືນຢັນ',
      showCancelButton: true,
    })

    if (!isConfirmed) {
      return
    }

    try {
      showLoading()

      await sendCoreRequest('/user/cancelorder', {
        orderid: order.orderid,
      })

      cancelLoading()
      dispatch('cancel', order)
    } catch (error) {
      cancelLoading()
      await handleAxiosError(error)
    }
  }

  function showDetail(order: Order) {
    return
    modalStore.trigger({
      type: 'component',
      component: {
        ref: ExchangeDetailModal,
        props: {
          id: order.orderid,
        },
      },
    })
  }
</script>

{#if orders.length === 0}
  <div class="card my-4 p-4 text-center text-sm">ບໍ່ມີລາຍການ</div>
{/if}

{#each orders as order}
  {@const percent = _.round(((order.fullAmount - order.remainAmount) / order.fullAmount) * 100, 0)}

  <div class="card space-y-4 p-4" on:click={() => showDetail(order)}>
    <header class="space-y-2">
      <div class="flex items-center justify-between">
        {#if order.direction === 'BUY'}
          <div class="flex items-center space-x-2 font-bold text-white">
            <img src="/images/ccy-lak-rounded.svg" class="size-8" />
            <Icon icon="mdi:arrow-right-bold" class="h-4 w-4" />
            <img src="/images/ccy-{order.ccy.toLocaleLowerCase()}-rounded.svg" class="size-8" />
          </div>
        {:else}
          <div class="flex items-center space-x-2 font-bold text-white">
            <img src="/images/ccy-{order.ccy.toLocaleLowerCase()}-rounded.svg" class="size-8" />
            <Icon icon="mdi:arrow-right" class="h-4 w-4" />
            <img src="/images/ccy-lak-rounded.svg" class="size-8" />
          </div>
        {/if}

        {#if order.status === 'ACTIVE'}
          <button type="button" class="btn space-x-1 rounded-lg bg-red-600 px-2 py-1 text-xs text-white shadow" on:click|stopPropagation={() => cancelOrder(order)}>
            <Icon icon="mdi:close" class="h-4 w-4" inline={true} />
            <span>ຍົກເລີກ</span>
          </button>
        {/if}
      </div>

      <div class="flex items-center space-x-4 text-xs text-white">
        <span>{dayjs(order.txtime).format('DD/MM/YYYY HH:mm:ss')}</span>
        <span class="flex-1">
          ID: <span class="select-text">{order.orderid}</span>
        </span>
        <span class="text-right text-xs font-light">ແລກແລ້ວ {percent}%</span>
      </div>

      <div class="space-y-1">
        <ProgressBar value={percent} max={100} height="h-1.5" meter={order.direction === 'BUY' ? 'bg-buy' : 'bg-sell'} track="bg-gray-200" />
      </div>
    </header>

    <main class="flex items-center space-x-8">
      <!--      <ProgressRadial-->
      <!--        width="w-16"-->
      <!--        value={percent}-->
      <!--        font={120}-->
      <!--        track="stroke-white"-->
      <!--        meter={order.direction === 'BUY' ? 'stroke-secondary-600' : 'stroke-primary-600'}>-->
      <!--        {percent}%-->
      <!--      </ProgressRadial>-->

      <div class="flex-1 text-sm">
        <div class="flex items-center justify-between space-x-2">
          <div class="">ອັດຕາແລກປ່ຽນ:</div>
          <div class="font-bold">{formatNumber(order.price)}</div>
        </div>
        <div class="flex items-center justify-between space-x-2">
          <div class="">ຈະຕ້ອງໃຊ້:</div>
          <div class="font-bold">{order.direction === 'BUY' ? `${formatNumber(order.fullAmount * order.price)} LAK` : `${formatNumber(order.fullAmount)} ${order.ccy}`}</div>
        </div>
        <div class="flex items-center justify-between space-x-2">
          <div class="">ຈະໄດ້ຮັບ:</div>
          <div class="font-bold">{order.direction === 'BUY' ? `${formatNumber(order.fullAmount)} ${order.ccy}` : `${formatNumber(order.fullAmount * order.price)} LAK`}</div>
        </div>

        {#if order.status === 'ACTIVE'}
          <div class="flex items-center justify-between space-x-2">
            <div class="">ແລກສຳເລັດ:</div>
            <div class="{order.direction === 'BUY' ? 'text-buy' : 'text-sell'} font-bold">{formatNumber(order.fullAmount - order.remainAmount)} {order.ccy}</div>
          </div>
        {/if}
      </div>
    </main>
  </div>
{/each}
