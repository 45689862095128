<script lang="ts">
  import '$lib/css/app.scss'

  import { onMount } from 'svelte'
  import { storePopup, Modal, initializeStores } from '@skeletonlabs/skeleton'
  import { isLoading, portfolio, isAuthenticated, authData, publicKey, privateKey } from '$lib/store'
  import { subscribeToMarket, subscribeToOrderBook } from '$lib/socket'
  import { computePosition, autoUpdate, offset, shift, flip, arrow } from '@floating-ui/dom'
  import { enableCache } from '@iconify/svelte'
  import { showPage } from '$lib/page'

  import Page from '$lib/components/Page.svelte'
  import HomePage from '$lib/routes/home/+page.svelte'
  import MarketPage from '$lib/routes/market/+page.svelte'
  import HistoryPage from '$lib/routes/history/+page.svelte'
  import UserPage from '$lib/routes/user/+page.svelte'
  import TransferPage from '$lib/routes/transfer/+page.svelte'
  import { closeApp, sendCoreRequest, updateAccountBalance } from '$lib/utils/app'
  import { handleAxiosError, showAlert } from '$lib/utils/alert'
  import JSEncrypt from 'jsencrypt'
  import { showLoading, cancelLoading } from '$lib/utils'
  import LoadingIndicator from '$lib/components/LoadingIndicator.svelte'
  import _ from 'lodash'

  enableCache('local')
  initializeStores()
  storePopup.set({ computePosition, autoUpdate, offset, shift, flip, arrow })

  onMount(() => {
    initApp()
    subscribeToMarket()
    subscribeToOrderBook()
  })

  async function initApp() {
    try {
      // CHECK AUTH TOKEN
      const urlParams = new URLSearchParams(window.location.search)
      if (!urlParams.has('authtoken')) {
        await showAlert('error', 'Auth token not found')
        closeApp()
        return
      }

      showLoading()

      const authToken = urlParams.get('authtoken')

      // GENERATE KEYPAIR
      const crypt = new JSEncrypt({ default_key_size: '2048' })
      $publicKey = crypt.getPublicKey()
      $privateKey = crypt.getPrivateKey()

      // AUTHEN WITH SERVER
      try {
        const { data } = await sendCoreRequest('/user/auth', {
          authtoken: authToken,
          publickey: $publicKey,
        })

        $authData = {
          sessionId: data.sessionid,
          origin: data.origin,
        }
      } catch (error) {
        await handleAxiosError(error)
        closeApp()
        return
      }

      // GET PORTFOLIO
      try {
        const { data } = await sendCoreRequest('/user/portfolio')

        // Custom sort account CCY
        const desiredOrder = ['LAK', 'USD', 'THB', 'CNY']
        data.accounts = _.sortBy(data.accounts, (account) => desiredOrder.indexOf(account.ccy))

        $portfolio = data
      } catch (error) {
        await handleAxiosError(error)
        closeApp()
        return
      }

      // REMOVE AUTH TOKEN
      if (import.meta.env.MODE !== 'development') {
        const url = new URL(window.location.href)
        url.searchParams.delete('authtoken')
        window.history.replaceState({}, document.title, url)
      }

      $isAuthenticated = true

      cancelLoading()
      showPage('home')
    } catch (error) {
      console.error(error)
      await showAlert('error', 'Cannot initialize, please re-open the app')
      closeApp()
    }
  }
</script>

<svelte:window on:focus={updateAccountBalance} />

{#if $isLoading}
  <div class="absolute inset-0 z-[9999] flex items-center justify-center bg-main-black/60">
    <LoadingIndicator />
  </div>
{/if}

{#if $isAuthenticated === true}
  <Page name="home" component={HomePage} />
  <Page name="market" component={MarketPage} />
  <Page name="history" component={HistoryPage} />
  <Page name="transfer" component={TransferPage} />
  <Page name="user" component={UserPage} />
{/if}

<Modal />
