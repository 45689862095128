<script lang="ts">
  import { getContext, onMount, type SvelteComponent } from 'svelte'
  import { portal } from 'svelte-portal'
  import PriceChart from '$lib/components/PriceChart.svelte'
  import MarketPriceTable from '$lib/components/MarketPriceTable.svelte'
  import ActiveOrders from '$lib/components/ActiveOrders.svelte'
  import Exchange from '$lib/components/Exchange.svelte'
  import { Swal } from '$lib/utils/alert'
  import { historyBook } from '$lib/store'
  import type { PageContext } from '$lib/definitions'
  import { getPageState } from '$lib/page'
  import AccountBalanceCard from '$lib/components/AccountBalanceCard.svelte'
  import MarketHistoryTable from '$lib/components/MarketHistoryTable.svelte'
  import MarketSummaryCard from '$lib/components/MarketSummaryCard.svelte'

  const { setPage } = getContext<PageContext>('pageContext')
  let pageState = getPageState()

  let ccyList: any[] = ['USD', 'THB', 'CNY']

  let activeOrdersEle: SvelteComponent
  let ccy: string = 'USD'
  let marketTab: 'orderBook' | 'historyBook' = 'orderBook'

  onMount(() => {
    setPage({
      navbarTitle: 'ແລກປ່ຽນ',
    })

    // Auto select CCY
    ccy = pageState?.ccy || 'USD'

    activeOrdersEle.getActiveOrders()
  })

  function handlePlaceOrderSuccess() {
    Swal.fire({
      icon: 'success',
      text: 'ສຳເລັດ',
    })

    activeOrdersEle.getActiveOrders()
  }
</script>

<svelte:head>
  <title>Market</title>
</svelte:head>

<nav class="bg-main-black-10 -mr-4 ml-auto flex flex-1 items-center justify-center space-x-1 rounded-full" use:portal={'#navbar-context-portal'}>
  {#each ccyList as _ccy}
    <button type="button" class="rounded-lg border border-gray-400 px-4 py-1.5 text-center text-sm {ccy === _ccy ? 'border-white bg-green-700' : ''}" on:click={() => (ccy = _ccy)}>
      {_ccy}
    </button>
  {/each}
</nav>

<header class="flex flex-col px-4 py-2 md:flex-row md:items-end md:justify-between md:space-x-4" use:portal={'#navbar-portal'}>
  <AccountBalanceCard class="md:order-2 md:col-span-3 md:block" />
</header>

<div class="card m-4 mb-0 p-0">
  {#key ccy}
    <MarketSummaryCard {ccy} />
  {/key}
</div>

<div class="grid grid-cols-1 md:grid-cols-2">
  <section class="row-span-2">
    <nav class="m-4 flex items-center space-x-1 md:hidden">
      <button
        type="button"
        class="flex w-full flex-1 items-center border-b-4 p-2 text-white {marketTab === 'orderBook' ? 'border-primary' : 'border-gray-600'}"
        on:click={() => (marketTab = 'orderBook')}>
        <span class="flex-1 font-bold">ປຶ້ມສັ່ງຊື້-ຂາຍ</span>
      </button>

      <button
        type="button"
        class="flex w-full flex-1 items-center border-b-4 p-2 text-white {marketTab === 'historyBook' ? 'border-primary' : 'border-gray-600'}"
        on:click={() => (marketTab = 'historyBook')}>
        <span class="flex-1 font-bold">ປະຫວັດຕະຫຼາດ</span>
      </button>
    </nav>

    <main class="m-4 mb-0 space-y-4">
      <div class="hidden w-full flex-1 border-b-4 border-primary p-2 text-center text-white md:block">ປຶ້ມສັ່ງຊື້-ຂາຍ</div>

      <div class="card p-2 {marketTab === 'orderBook' ? '' : 'hidden md:block'}">
        <div class="overflow-hidden rounded-md">
          {#key ccy}
            <MarketPriceTable {ccy} />
          {/key}
        </div>
      </div>

      <!-------------------------------------------------------------------------------->

      <div class="hidden w-full flex-1 border-b-4 border-primary p-2 text-center text-white md:block">ປະຫວັດຕະຫຼາດ</div>

      <div class="card p-2 {marketTab === 'historyBook' ? '' : 'hidden md:block'}">
        <div class="overflow-hidden rounded-md">
          {#key ccy}
            <MarketHistoryTable {ccy} />
          {/key}
        </div>
      </div>
    </main>
  </section>

  {#key ccy}
    <Exchange class="mb-0" {ccy} on:placeOrderSuccess={handlePlaceOrderSuccess} />
  {/key}

  <ActiveOrders {ccy} bind:this={activeOrdersEle} />
</div>
