<script lang="ts">
  import { formatNumber, getCcyColor, getCcySymbol } from '$lib/utils'
  import { market, volumeBook } from '$lib/store'
  import type { MarketCCY, VolumeBookCCY } from '$lib/definitions'
  import PriceChart from '$lib/components/PriceChart.svelte'

  export let ccy: string = 'USD'

  let marketCCY: MarketCCY | undefined = undefined
  let volumeCCY: VolumeBookCCY | undefined = undefined

  $: if ($market && $market.ccys) {
    marketCCY = $market?.ccys.find((item: MarketCCY) => item.ccy === ccy)
  }

  $: if ($volumeBook) {
    // @ts-ignore
    volumeCCY = $volumeBook[ccy]
  }
</script>

{#if marketCCY}
  <section class="card space-y-2 p-2">
    <div class="flex items-end space-x-2">
      <img src="/images/ccy-{marketCCY.ccy.toLocaleLowerCase()}-rounded.svg" class="size-8 shrink-0 grow-0 self-center" />

      <div class="flex-1">
        <div class="text-sm font-light">{marketCCY.ccy} / LAK</div>
        <div class="space-x-2">
          <span class="text-lg font-bold leading-none">{formatNumber(marketCCY.lastPrice ?? 0, 2)}</span>
          <span class="text-xs font-light {(marketCCY.change ?? 0) > 0 ? 'text-buy' : 'text-sell'}">{formatNumber(marketCCY.change ?? 0, 2)}%</span>
        </div>
      </div>

      {#if volumeCCY}
        <div class="space-y-0.5 text-right">
          <div class="text-sm">{getCcySymbol(ccy)} {formatNumber(volumeCCY.volume, 2)}</div>
          <div class="text-xs font-light">{formatNumber(volumeCCY.count)} ລາຍການ</div>
        </div>
      {/if}
    </div>

    {#key ccy}
      <div class="overflow-hidden rounded-md">
        <PriceChart {ccy} />
      </div>
    {/key}
  </section>
{:else}
  <section class="card w-full">
    <div class="space-y-4 p-4">
      <div class="flex items-center space-x-2">
        <div class="placeholder size-8 animate-pulse rounded-full"></div>
        <div class="space-y-2">
          <div class="placeholder h-4 w-16 animate-pulse rounded-md"></div>
          <div class="placeholder h-4 w-28 animate-pulse rounded-md"></div>
        </div>
      </div>
      <div class="placeholder h-24 animate-pulse rounded-md"></div>
    </div>
  </section>
{/if}
