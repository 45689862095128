import { get } from 'svelte/store'
import { backstack, currentPage } from '$lib/store'

export function showPage(newPage, state?: object) {
  currentPage.set({
    name: newPage,
    state: state,
  })

  backstack.update((oldStack) => {
    if (oldStack.length !== 0) {
      if (newPage === oldStack[oldStack.length - 1]) {
        return oldStack
      } else {
        return [...oldStack, { name: newPage, state }]
      }
    } else {
      return [...oldStack, { name: newPage, state }]
    }
  })
}

export function replacePage(newPage, state?: object) {
  backstack.update((oldStack) => oldStack.slice(0, oldStack.length - 1))
  showPage(newPage, state)
}

export function goBack(backState?: object) {
  backstack.update((oldStack) => {
    if (oldStack.length == 0) {
      return []
    } else if (oldStack.length == 1) {
      let newPage = oldStack[0]
      newPage.backState = backState
      currentPage.set(newPage)
      return oldStack
    } else {
      let newPage = oldStack[oldStack.length - 2]
      newPage.backState = backState
      currentPage.set(newPage)
      return oldStack.slice(0, oldStack.length - 1)
    }
  })
}

export function backToHome() {
  backstack.update((oldStack) => [oldStack[0]])
  goBack()
}

export function getPageState(): object {
  return get(currentPage).state
}

export function getBackPageState(): object {
  return get(currentPage).backState
}
