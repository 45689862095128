import { coreAxios } from '$lib/utils/axios'
import { customAlphabet } from 'nanoid'
import { get } from 'svelte/store'
import { authData, privateKey, portfolio, isLoadingBalance } from '$lib/store'
import type { AxiosResponse } from 'axios'
import JSEncrypt from 'jsencrypt'
import sha256 from 'js-sha256'
import type { PortfolioAccount } from '$lib/definitions'

export function postMessage(params: object, origin: string | undefined = undefined) {
  console.log('Post message params', params)

  if (origin === undefined) {
    origin = get(authData)?.origin || '*'
  }
  window.parent.postMessage(params, origin)
}

export function closeApp() {
  postMessage({
    function: 'close',
  })
}

export async function sendCoreRequest(url: string, data: object | undefined = undefined): Promise<AxiosResponse> {
  const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')

  const payload = {
    ...data,
    timestamp: new Date().getTime(),
    requestid: nanoid(24),
    sessionid: get(authData)?.sessionId,
  }

  const signer = new JSEncrypt()
  signer.setPrivateKey(get(privateKey)!)

  // @ts-ignore
  const signature = signer.sign(JSON.stringify(payload), sha256, 'sha256')
  let hexSignature = base64ToHex(signature.toString())

  // Note: Fix sometime invalid hex signature length
  const sha256ByteLength = 512
  if (hexSignature.length < sha256ByteLength) {
    hexSignature = '0'.repeat(sha256ByteLength - hexSignature.length) + hexSignature
  }

  return coreAxios.post(url, payload, {
    headers: {
      'X-Signature': hexSignature,
    },
  })
}

export async function generateKeyPair(): Promise<CryptoKeyPair> {
  return window.crypto.subtle.generateKey(
    {
      name: 'RSASSA-PKCS1-v1_5',
      modulusLength: 2048,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: 'SHA-256',
    },
    true,
    ['sign', 'verify'],
  )
}

export function base64ToHex(base64: string): string {
  try {
    const raw = atob(base64)
    let hex = ''

    for (let i = 0; i < raw.length; i++) {
      const hexChar = raw.charCodeAt(i).toString(16)
      hex += hexChar.length === 2 ? hexChar : '0' + hexChar
    }

    return hex
  } catch (err) {
    return ''
  }
}

export function arrayBufferToHex(buffer: ArrayBuffer): string {
  return Array.from(new Uint8Array(buffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}

export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}

export function isIos(): boolean {
  // @ts-ignore
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

export async function updateAccountBalance() {
  try {
    isLoadingBalance.set(true)

    const { data } = await sendCoreRequest('/user/portfolio')
    const _portfolio = get(portfolio)

    if (_portfolio) {
      for (let account of data.accounts) {
        const accIndex = _portfolio.accounts.findIndex((acc: PortfolioAccount) => acc.account === account.account)
        _portfolio.accounts[accIndex].availablebalance = account.availablebalance
      }
      portfolio.set(_portfolio)
    }

    isLoadingBalance.set(false)
  } catch (error) {
    console.error('Update balance error', error)
    isLoadingBalance.set(false)
  }
}
