<script lang="ts">
  import { cancelLoading, formatNumber, getCcyColor, getCcySymbol, getDirectionSymbol, showLoading } from '$lib/utils'
  import Icon from '@iconify/svelte'
  import dayjs from 'dayjs'
  import type { ExchangeHistory, TransferHistory, PageContext } from '$lib/definitions'
  import { getContext, onMount } from 'svelte'
  import { portal } from 'svelte-portal'
  import { getModalStore } from '@skeletonlabs/skeleton'
  import { handleAxiosError } from '$lib/utils/alert'
  import { sendCoreRequest } from '$lib/utils/app'
  import AccountBalanceCard from '$lib/components/AccountBalanceCard.svelte'
  import ExchangeDetailModal from '$lib/components/ExchangeDetailModal.svelte'
  import TransferDetailModal from '$lib/components/TransferDetailModal.svelte'

  const modalStore = getModalStore()
  const { setPage } = getContext<PageContext>('pageContext')

  let tab: 'exchange' | 'transfer' = 'exchange'
  let isLoadingHistory = false
  let exchangeItems: ExchangeHistory[] = []
  let transferItems: TransferHistory[] = []

  onMount(() => {
    setPage({
      navbarTitle: 'ປະຫວັດ',
    })

    changeTab('exchange')
  })

  async function getExchangeHistory() {
    try {
      isLoadingHistory = true

      const { data } = await sendCoreRequest('/user/gethistory')
      exchangeItems = data.histories

      isLoadingHistory = false
    } catch (error) {
      isLoadingHistory = false
      await handleAxiosError(error)
    }
  }

  async function getTransferHistory() {
    try {
      isLoadingHistory = true

      const { data } = await sendCoreRequest('/user/gettransferhistory')
      transferItems = data.histories.filter((item: TransferHistory) => item.result === 'OK')

      isLoadingHistory = false
    } catch (error) {
      isLoadingHistory = false
      await handleAxiosError(error)
    }
  }

  function changeTab(_tab: 'exchange' | 'transfer') {
    tab = _tab
    refreshHistory()
  }

  function refreshHistory() {
    if (tab === 'exchange') {
      getExchangeHistory()
      return
    }

    if (tab === 'transfer') {
      getTransferHistory()
      return
    }
  }

  function showExchangeDetailModal(history: ExchangeHistory) {
    modalStore.trigger({
      type: 'component',
      component: {
        ref: ExchangeDetailModal,
        props: {
          history,
        },
      },
    })
  }

  function showTransferDetailModal(history: TransferHistory) {
    modalStore.trigger({
      type: 'component',
      component: {
        ref: TransferDetailModal,
        props: {
          history,
        },
      },
    })
  }
</script>

<svelte:head>
  <title>ປະຫວັດ</title>
</svelte:head>

<header class="px-4 py-2 md:mx-auto md:w-max" use:portal={'#navbar-portal'}>
  <AccountBalanceCard />
</header>

<button type="button" class="-mr-4" on:click={refreshHistory} use:portal={'#navbar-context-portal'}>
  <Icon icon="mdi:refresh" class="size-7 text-white" />
</button>

<header class="sticky left-0 top-0 -mb-4 flex items-center space-x-1 bg-main-black p-4">
  <button type="button" class="flex w-full flex-1 items-center border-b-4 p-2 text-white {tab === 'exchange' ? 'border-primary' : 'border-gray-600'}" on:click={() => changeTab('exchange')}>
    <span class="flex-1 font-bold">ແລກປ່ຽນ</span>
  </button>

  <button type="button" class="flex w-full flex-1 items-center border-b-4 p-2 text-white {tab === 'transfer' ? 'border-primary' : 'border-gray-600'}" on:click={() => changeTab('transfer')}>
    <span class="flex-1 font-bold">ຝາກ / ຖອນ</span>
  </button>
</header>

{#if isLoadingHistory}
  <aside class="m-4 space-y-2">
    {#each Array(6) as _}
      <div class="card">
        <div class="flex items-center space-x-3 p-3">
          <div class="flex-1 space-y-3">
            <div class="placeholder animate-pulse"></div>
            <div class="placeholder animate-pulse"></div>
          </div>
        </div>
      </div>
    {/each}
  </aside>
{/if}

{#if tab === 'exchange'}
  {#if !isLoadingHistory && exchangeItems.length === 0}
    <div class="card m-4 p-4 text-center text-sm">ບໍ່ພົບລາຍການ</div>
  {/if}

  {#if !isLoadingHistory && exchangeItems.length > 0}
    <section class="m-4 divide-y divide-main-black/30 overflow-hidden rounded-xl">
      {#each exchangeItems as history}
        <div class="flex space-x-3 bg-[#2A2A2D] p-3">
          <img src="/images/ccy-{history.ccy?.toLocaleLowerCase()}-rounded.svg" class="size-8" />

          <div class="flex-1">
            <div class="space-y-0.5 text-xs font-light text-gray-300">
              <div class="font-normal text-white">{dayjs(history.txtime).format('DD/MM/YYYY HH:mm:ss')}</div>
              <div>
                ID: <span class="select-text">{history.orderid}</span>
              </div>
              <div>ຄ່າທຳນຽມ: {formatNumber(history.fee ?? 0, 2)} {history.ccy}</div>

              {#if history.direction === 'BUY'}
                <div>{formatNumber(history.price, 2)} LAK / {history.ccy}</div>
              {:else}
                <div>{formatNumber(history.price, 2)} {history.ccy} / LAK</div>
              {/if}
            </div>
          </div>

          <div class="flex-1 space-y-0.5 text-right text-sm">
            <div class="font-bold {history.direction === 'BUY' ? 'text-buy' : 'text-sell'}">{history.direction === 'BUY' ? 'ຊື້' : 'ຂາຍ'}</div>
            <div class="font-bold">{getCcySymbol(history.ccy ?? '')} {formatNumber(history.amount, 2)}</div>
            <div class="text-xs font-normal text-gray-300">≈ {formatNumber(history.amount * history.price)} LAK</div>
          </div>
        </div>
      {/each}
    </section>
  {/if}
{/if}

{#if tab === 'transfer'}
  {#if !isLoadingHistory && transferItems.length === 0}
    <div class="card m-4 p-4 text-center text-sm">ບໍ່ພົບລາຍການ</div>
  {/if}

  {#if !isLoadingHistory && transferItems.length > 0}
    <section class="m-4 divide-y divide-main-black/30 overflow-hidden rounded-xl">
      {#each transferItems as history}
        <div class="flex space-x-3 bg-[#2A2A2D] p-3" on:click={() => showTransferDetailModal(history)}>
          <img src="/images/ccy-{history.ccy?.toLocaleLowerCase()}-rounded.svg" class="size-8" />

          <div class="flex-1">
            <div class="space-y-0.5 text-xs font-light text-gray-300">
              <div class="font-normal text-white">{dayjs(history.txtime).format('DD/MM/YYYY HH:mm:ss')}</div>
              <div>
                ID: <span class="select-text">{history.reference}</span>
              </div>
              <div>ຄ່າທຳນຽມ: {formatNumber(history.fee ?? 0, 2)} {history.ccy}</div>
            </div>
          </div>

          <div class="flex-1 space-y-0.5 text-right text-sm">
            <div class="font-bold {history.direction === 'IN' ? 'text-buy' : 'text-sell'}">{history.direction === 'IN' ? 'ຝາກ' : 'ຖອນ'}</div>
            <div class="font-bold">{getCcySymbol(history.ccy ?? '')} {formatNumber(history.amount, 2)}</div>
          </div>
        </div>
      {/each}
    </section>
  {/if}
{/if}
