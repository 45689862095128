<script lang="ts">
  import InputMask from '$lib/components/InputMask.svelte'
  import type { PortfolioAccount } from '$lib/definitions'
  import { portfolio } from '$lib/store'
  import { showAlert } from '$lib/utils/alert'
  import { postMessage } from '$lib/utils/app'
  import { customAlphabet } from 'nanoid'
  import { onMount } from 'svelte'

  const amountMaskOption = {
    mask: Number,
    scale: 2,
    thousandsSeparator: ',',
    padFractionalZeros: false,
    normalizeZeros: true,
    radix: '.',
    mapToRadix: ['.', ','],
    min: 0,
    max: 1000000000000,
  }

  let amount: number = 0
  let account: PortfolioAccount

  onMount(() => {
    if ($portfolio) {
      account = $portfolio.accounts[0]
    }
  })

  async function deposit() {
    if (amount <= 0) {
      await showAlert('warning', 'ກະລຸນາປ້ອນຈຳນວນເງິນ')
      return
    }

    try {
      const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
      postMessage({
        function: 'deposit',
        tomember: 'LFX',
        toaccount: account.account,
        ccy: account.ccy,
        amount: amount,
        frommember: $portfolio!.bank,
        requestid: nanoid(24),
      })
    } catch (error) {
      console.error(error)
    }
  }
</script>

<div class="card space-y-4 p-4 {$$restProps.class}">
  {#if $portfolio}
    <div class="flex items-center justify-center space-x-2">
      {#each $portfolio.accounts as _account}
        <button
          type="button"
          class="rounded-lg border border-gray-400 px-4 py-2 text-center text-sm {account?.ccy === _account.ccy ? 'border-white bg-green-700' : ''}"
          on:click={() => (account = _account)}>
          {_account.ccy}
        </button>
      {/each}
    </div>
  {/if}

  <div class="space-y-1">
    <div class="text-center text-sm">ປ້ອນຈຳນວນ</div>
    <InputMask
      class="input text-center text-gray-600"
      inputmode="decimal"
      autocapitalize="off"
      autocomplete="off"
      spellcheck="false"
      value={amount}
      option={amountMaskOption}
      on:input={({ detail }) => (amount = detail)} />
  </div>

  <button type="button" class="variant-filled-primary btn w-full" on:click={deposit}>ຝາກເງິນ</button>
</div>
