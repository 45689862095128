<script lang="ts">
  import IMask from 'imask'
  import { createEventDispatcher, onMount, onDestroy } from 'svelte'

  export let option

  export let value = 0

  const dispatch = createEventDispatcher()
  let element: HTMLInputElement
  let mask: IMask
  let attrs

  // Copy props properties to attrs except key "value", "option"
  $: ({ value, option, ...attrs } = $$props)

  // Show 0 as empty string for Number mask
  $: if (mask && option.mask === Number) {
    mask.value = parseFloat(value.toString()) > 0 ? value.toString() : ''
  }

  onMount(() => {
    mask = IMask(element, option)
  })

  onDestroy(() => {
    if (mask) {
      mask.destroy()
    }
  })

  async function dispatchValue() {
    dispatch('input', option.mask === Number ? mask.typedValue : mask.value)
  }
</script>

<input type="text" {...attrs} bind:this="{element}" on:keyup="{dispatchValue}" />
