<script lang="ts">
  import Icon from '@iconify/svelte'
  import { portfolio } from '$lib/store'
</script>

{#if $portfolio}
  <section class="flex items-center space-x-4 {$$restProps.class}">
    <div class="flex size-14 items-center justify-center rounded-full bg-[#2A2A2D] p-2">
      <Icon icon="mdi:account" class="size-16" />
    </div>
    <div class="flex-1">
      <div>{$portfolio.firstname} {$portfolio.lastname}</div>
      <div class="text-sm">ທະນາຄານ: {$portfolio.bank}</div>
    </div>
  </section>
{/if}
