<script lang="ts">
  import { portfolio } from '$lib/store'
  import { formatNumber, getCcySymbol } from '$lib/utils'
  import Icon from '@iconify/svelte'
  import { updateAccountBalance } from '$lib/utils/app'
</script>

{#if $portfolio}
  <div class="relative {$$restProps.class}">
    <!--    <button type="button" class="absolute top-1 right-5 z-50" on:click={updateAccountBalance}>-->
    <!--      <Icon icon="mdi:refresh" class="size-5 text-white"/>-->
    <!--    </button>-->

    <section class="grid grid-cols-2 gap-x-4 gap-y-1 text-xs md:grid-cols-4 md:gap-y-0">
      {#each $portfolio.accounts as account, index (account.account)}
        <div class="col-span-1 flex items-center space-x-2">
          <img src="/images/ccy-{account.ccy.toLocaleLowerCase()}-rounded.svg" class="size-5" />
          <div class="text-xs">{getCcySymbol(account.ccy)} {formatNumber(account.availablebalance, 2)}</div>
        </div>
      {/each}
    </section>
  </div>
{/if}
