<script lang="ts">
  import _ from 'lodash'
  import { ColorType, createChart, CrosshairMode, type IChartApi, type ISeriesApi } from 'lightweight-charts'
  import { market } from '$lib/store'
  import type { MarketCCY } from '$lib/definitions'
  import { onMount } from 'svelte'
  import dayjs from 'dayjs'
  import dayjsUtc from 'dayjs/plugin/utc'
  import dayjsTimezone from 'dayjs/plugin/timezone'
  import { formatNumber } from '$lib/utils'

  dayjs.extend(dayjsUtc)
  dayjs.extend(dayjsTimezone)

  export let ccy: string = ''

  let chartContainer: HTMLDivElement
  let chart: IChartApi
  let candlestickSeries: ISeriesApi<'Candlestick'>
  let data: any[] = []

  $: if ($market !== null) {
    data = []
    const matchedCcy = _.find($market.ccys, { ccy }) as MarketCCY | undefined

    if (matchedCcy !== undefined) {
      for (let trade of matchedCcy.trades) {
        const item = {
          ...trade,
          time: dayjs.utc(trade.time).tz('Asia/Vientiane').unix(),
          time2: dayjs(trade.time).format('YYYY-MM-DD HH:mm:ss'),
        }
        data.push(item)
      }
      candlestickSeries?.setData(data)
      chart?.timeScale()?.fitContent()
    }
  }

  onMount(() => {
    initChart()
  })

  async function initChart() {
    const chartOptions = {
      autoSize: true,
      layout: {
        background: {
          type: ColorType.Solid,
          color: 'rgba(6, 15, 6, 1)',
        },
        textColor: 'rgba(255, 255, 255, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
        horzLines: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      rightPriceScale: {
        borderColor: 'rgba(255, 255, 255, 0.4)',
      },
      timeScale: {
        borderColor: 'rgba(255, 255, 255, 0.4)',
        timeVisible: true,
        secondsVisible: false,
        fixLeftEdge: true,
        fixRightEdge: true,
      },
      localization: {
        timeFormatter: (time: number) => {
          return dayjs(time * 1000)
            .utc()
            .format('YYYY-MM-DD HH:mm')
        },
        priceFormatter: (price: number) => {
          return formatNumber(price, 2)
        },
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: false,
        horzTouchDrag: false,
        vertTouchDrag: false,
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
        pinch: false,
      },
    }

    chart = createChart(chartContainer, chartOptions)

    candlestickSeries = chart.addCandlestickSeries({
      upColor: '#22C55E',
      downColor: '#EF4444',
      borderVisible: false,
      wickUpColor: '#22C55E',
      wickDownColor: '#EF4444',
    })

    candlestickSeries.setData([])

    chart.timeScale().fitContent()
  }
</script>

<div bind:this={chartContainer} class="h-28 md:h-40 {$$restProps.class}"></div>
