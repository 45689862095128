<script lang="ts">
  import { getModalStore } from '@skeletonlabs/skeleton'
  import type { TransferHistory } from '$lib/definitions'
  import dayjs from 'dayjs'
  import { formatNumber, getCcySymbol } from '$lib/utils'
  import Icon from '@iconify/svelte'

  export let history: TransferHistory

  const modalStore = getModalStore()
</script>

{#if history}
  <section class="w-full space-y-4">
    <div class="card relative flex space-x-3 bg-[#2A2A2D] p-3">
      <img src="/images/ccy-{history.ccy?.toLocaleLowerCase()}-rounded.svg" class="size-8" />

      <div class="flex-1">
        <div class="space-y-1 text-xs font-light text-gray-300">
          <div class="font-normal text-white">{dayjs(history.txtime).format('DD/MM/YYYY HH:mm:ss')}</div>
          <div>
            ID: <span class="select-text">{history.reference}</span>
          </div>
          <div>ຄ່າທຳນຽມ: {formatNumber(history.fee ?? 0, 2)} {history.ccy}</div>

          <div class="!mt-4 space-y-4">
            {#if history.direction === 'IN'}
              <div class="space-y-1">
                <div>ຕົ້ນທາງ: {history.frommember}</div>
                <div>
                  ບັນຊີ: <span class="select-text">{history.fromaccount}</span>
                </div>
              </div>

              <div class="space-y-1">
                <div>ປາຍທາງ: LFX</div>
                <div>
                  ບັນຊີ: <span class="select-text">{history.toaccount}</span>
                </div>
              </div>
            {:else}
              <div class="space-y-1">
                <div>ຕົ້ນທາງ: LFX</div>
                <div>
                  ບັນຊີ: <span class="select-text">{history.fromaccount}</span>
                </div>
              </div>

              <div class="space-y-1">
                <div>ປາຍທາງ: {history.frommember}</div>
                <div>
                  ບັນຊີ: <span class="select-text">{history.toaccount}</span>
                </div>
              </div>
            {/if}
          </div>
        </div>
      </div>

      <div class="absolute right-3 top-3 flex-1 space-y-1 text-right text-sm">
        <div class="font-bold {history.direction === 'IN' ? 'text-buy' : 'text-sell'}">{history.direction === 'IN' ? 'ຝາກ' : 'ຖອນ'}</div>
        <div class="font-bold">{getCcySymbol(history.ccy ?? '')} {formatNumber(history.amount, 2)}</div>
      </div>
    </div>

    <button type="button" class="btn btn-icon mx-auto flex items-center justify-center bg-[#2A2A2D] opacity-70 !outline-none" on:click={modalStore.close}>
      <Icon icon="mdi:close" class="h-6 w-6 text-white" on:click={() => modalStore.close()} />
    </button>
  </section>
{/if}
