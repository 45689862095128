import { isLoading, initData } from '$lib/store'
import _ from 'lodash'
import { getModalStore } from '@skeletonlabs/skeleton'
import { get } from 'svelte/store'
import AccountSettingModal from '$lib/components/AccountSettingModal.svelte'
import TermModal from '$lib/components/TermModal.svelte'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import isBetween from 'dayjs/plugin/isBetween'
import type { InitDataAccount } from '$lib/definitions'
import TwoFactorModal from '$lib/components/TwoFactorModal.svelte'
import { customAlphabet } from 'nanoid'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)

const callbackList: any = {}
let onMessageSet = false

// Set callback listener from B1Hybrid
if (!onMessageSet) {
  onMessageSet = true
  window.addEventListener('message', async ({ data }) => {
    const callbackTypes = ['sendMessageCallback', 'getSettingCallback', 'isBiometricSupportedCallback', 'signBiometricCallback', 'loadUserCallback']

    if (callbackTypes.includes(data.type)) {
      const callback = callbackList[data.callbackId]
      if (callback) callback(data.data)
      delete callbackList[data.callbackId]
    }
  })
}

// ================================================================================

export const priceMaskOption = {
  mask: Number,
  scale: 2,
  thousandsSeparator: ',',
  padFractionalZeros: false,
  normalizeZeros: true,
  radix: '.',
  mapToRadix: ['.', ','],
  min: 0,
  max: 1000000,
}

export const exchangeAmountMaskOption = {
  mask: Number,
  scale: 2,
  thousandsSeparator: ',',
  padFractionalZeros: false,
  normalizeZeros: true,
  radix: '.',
  mapToRadix: ['.', ','],
  min: 0,
  max: 1000000000000,
}

// ================================================================================

export function showLoading() {
  isLoading.set(true)
}

export function cancelLoading() {
  isLoading.set(false)
}

export function getCcySymbol(ccy: string): string {
  switch (ccy) {
    case 'LAK':
      return '₭'
    case 'USD':
      return '$'
    case 'THB':
      return '฿'
    case 'CNY':
      return '¥'
    default:
      return ''
  }
}

export function getCcyColor(ccy: string): string {
  switch (ccy) {
    case 'LAK':
      return 'text-blue-500'
    case 'USD':
      return 'text-green-500'
    case 'THB':
      return 'text-red-500'
    case 'CNY':
      return 'text-orange-500'
    default:
      return ''
  }
}

export function getCcyFlag(ccy: 'LAK' | 'THB' | 'USD' | 'CNY' | 'BTC' | 'ETH' | 'SOL'): string {
  switch (ccy) {
    case 'LAK':
      return ''
    case 'USD':
      return '/images/flag/usa.svg'
    case 'THB':
      return '/images/flag/thailand.svg'
    case 'CNY':
      return '/images/flag/china.svg'
    case 'BTC':
      return '/images/flag/btc.svg'
    case 'ETH':
      return '/images/flag/eth.svg'
    case 'SOL':
      return '/images/flag/sol.svg'
    default:
      return ''
  }
}

export function getDirectionSymbol(direction: 'BUY' | 'SELL'): string {
  switch (direction) {
    case 'BUY':
      return '/images/ic_arrow_buy.svg'
    case 'SELL':
      return '/images/ic_arrow_sell.svg'
    default:
      return ''
  }
}

export function getChartFlag(volume: 0): string {
  if (volume >= 0) return '/images/green-chart.svg'
  return '/images/red-chart.svg'
}

export function closePopupB1() {
  window.parent.postMessage({ type: 'closePopup' }, '*')
}

export function sendMessageB1(params: any, callback: CallableFunction) {
  const callbackId = (1000000000 + Math.random() * 999999999).toString()
  callbackList[callbackId] = callback

  params = {
    ...params,
    gitcommithash: import.meta.env.VITE_GIT_COMMIT_HASH,
    gitcommitdate: import.meta.env.VITE_GIT_COMMIT_DATE,
  }

  window.parent.postMessage({ type: 'sendMessage', data: params, callbackId }, '*')
}

export function setSettingB1(key: string, value: string, secure: boolean) {
  window.parent.postMessage({ type: 'setSetting', data: { key, value, secure } }, '*')
}

export function getSettingB1(key: string, secure: boolean, callback: CallableFunction) {
  const callbackId = (1000000000 + Math.random() * 999999999).toString()
  callbackList[callbackId] = callback
  window.parent.postMessage({ type: 'getSetting', data: { key, secure }, callbackId }, '*')
}

export function isBiometricSupportedB1(callback: CallableFunction) {
  const callbackId = (1000000000 + Math.random() * 999999999).toString()
  callbackList[callbackId] = callback
  window.parent.postMessage({ type: 'isBiometricSupported', callbackId }, '*')
}

export function signBiometricB1(verifyToken: string, callback: CallableFunction) {
  const callbackId = (1000000000 + Math.random() * 999999999).toString()
  callbackList[callbackId] = callback
  window.parent.postMessage({ type: 'signBiometric', data: { verifyToken }, callbackId }, '*')
}

export function loadUserB1(callback: CallableFunction) {
  const callbackId = (1000000000 + Math.random() * 999999999).toString()
  callbackList[callbackId] = callback
  window.parent.postMessage({ type: 'loadUser', callbackId }, '*')
}

export function verifyFaceB1() {
  window.parent.postMessage({ type: 'verifyFace' }, '*')
}

// export function initApp() {
//   showLoading()
//   sendMessageB1({ command: 'init' }, async (r: any) => {
//     cancelLoading()
//     console.log('init', r)
//
//     if (r.result !== 0) {
//       const { isConfirmed } = await Swal.fire({
//         icon: 'error',
//         text: r.message,
//         confirmButtonText: 'ຕົກລົງ',
//       })
//
//       if (isConfirmed) {
//         closePopupB1()
//       }
//       return
//     }
//
//     initData.set(r)
//     isAuthenticated.set(r.isAuthenticated)
//
//     // SHOW MODALS, TOP SHOW FIRST
//
//     if (!r.isAuthenticated) {
//       showTwoFactorModal()
//     }
//
//     if (!isInitAccountsValid()) {
//       showAccountSettingModal()
//     }
//
//     getSettingB1('MONEYMARKET_ACCEPT_TERM', false, (settingVal: string) => {
//       if (settingVal !== 'true') {
//         showTermModal()
//       }
//     })
//
//   })
// }

export function isInitAccountsValid() {
  let isValid = false
  const group = _.groupBy(get(initData)?.accounts, 'ccy')

  for (const [ccy, accounts] of Object.entries(group)) {
    if (!isCcyAccountSelected(accounts, ccy)) {
      isValid = false
      break
    } else {
      isValid = true
    }
  }

  return isValid
}

export function isCcyAccountSelected(accounts: InitDataAccount[], ccy: string) {
  return _.filter(accounts, { ccy, selected: 1 }).length > 0
}

export function showAccountSettingModal() {
  if (get(initData)?.accounts.length === 0) return

  const modalStore = getModalStore()

  modalStore.trigger({
    type: 'component',
    backdropClasses: 'account-setting-modal',
    component: {
      ref: AccountSettingModal,
    },
  })
}

export function showTermModal() {
  const modalStore = getModalStore()

  modalStore.trigger({
    type: 'component',
    backdropClasses: 'term-modal',
    component: {
      ref: TermModal,
    },
  })
}

export function showTwoFactorModal() {
  const modalStore = getModalStore()

  modalStore.trigger({
    type: 'component',
    backdropClasses: 'two-factor-modal',
    component: {
      ref: TwoFactorModal,
    },
  })
}

export function formatNumber(value: number | string, decimal?: number): string {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  return decimal !== undefined ? value.toLocaleString('en-US', { minimumFractionDigits: decimal, maximumFractionDigits: decimal }) : value.toLocaleString('en-US')
}

export function isMarketOpen(openDay: number, openTime = '09:00', closeDay: number, closeTime = '15:30'): boolean {
  const openHour = parseInt(openTime.split(':')[0])
  const openMinute = parseInt(openTime.split(':')[1])
  const closeHour = parseInt(closeTime.split(':')[0])
  const closeMinute = parseInt(closeTime.split(':')[1])

  const now = dayjs().tz('Asia/Vientiane')
  const dayOfWeek = now.day()

  if (dayOfWeek >= openDay && dayOfWeek <= closeDay) {
    const open = now.clone().hour(openHour).minute(openMinute)
    const close = now.clone().hour(closeHour).minute(closeMinute)

    return now.isBetween(open, close, 'minute', '[]')
  }

  return false
}

export function getDayName(dayNum: number): string {
  switch (dayNum) {
    case 1:
      return 'ຈັນ'
    case 2:
      return 'ຄານ'
    case 3:
      return 'ພຸດ'
    case 4:
      return 'ພະຫັດ'
    case 5:
      return 'ສຸກ'
    case 6:
      return 'ເສົາ'
    case 7:
      return 'ອາທິດ'
    default:
      return ''
  }
}

export function getCcyName(ccy: 'LAK' | 'THB' | 'USD' | 'CNY'): string {
  switch (ccy) {
    case 'LAK':
      return 'ກີບ'
    case 'THB':
      return 'ບາດ'
    case 'USD':
      return 'ໂດລາ'
    case 'CNY':
      return 'ຢວນ'
    default:
      return ''
  }
}

export function generateRandomString(length: number = 16): string {
  const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', length)
  return nanoid()
}
