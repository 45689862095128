import App from './App.svelte'
import HyperDX from '@hyperdx/browser'
import axios from 'axios'

// import * as Sentry from '@sentry/svelte'

// if (import.meta.env.VITE_ENABLE_SENTRY === 'true') {
//   Sentry.init({
//     dsn: 'https://5623270186be4cdbbf497d6907cb2078@o4505441487618048.ingest.sentry.io/4505441493057536',
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       // Sentry.replayIntegration(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 0.5, // Original 1.0, Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   })
// }

// ================================================================================

if (import.meta.env.MODE === 'production') {
  HyperDX.init({
    apiKey: '7d951395-4687-4311-a78e-f5c2353cbabe',
    service: 'LFX',
    consoleCapture: false, // Capture console logs (default false)
    advancedNetworkCapture: false, // Capture full HTTP request/response headers and bodies (default false)
  })

  // @ts-ignore
  window.onerror = (message, url, line, column, error: Error) => {
    console.error('Global error handler', error)

    try {
      axios.post('https://b1logs.bcel.one/log/lfx', {
        source: url,
        lineNo: line,
        columnNo: column,
        error: error,
        errorStack: error.stack,
        userAgent: navigator.userAgent,
      })
    } catch (error) {
      console.error('Cannot send global error log to server', error)
    }
  }
}

// ================================================================================

const app = new App({
  target: document.getElementById('app')!,
})

export default app
