<script lang="ts">
  import _ from 'lodash'
  import { createEventDispatcher, onMount } from 'svelte'
  import Icon from '@iconify/svelte'
  import type { PopupSettings } from '@skeletonlabs/skeleton'
  import { popup } from '@skeletonlabs/skeleton'
  import { orderBook, portfolio } from '$lib/store'
  import { cancelLoading, exchangeAmountMaskOption, formatNumber, priceMaskOption, showLoading } from '$lib/utils'
  import InputMask from '$lib/components/InputMask.svelte'
  import type { OrderBookCCY, OrderBookCCYDetail } from '$lib/definitions'
  import { sendCoreRequest, updateAccountBalance } from '$lib/utils/app'
  import { handleAxiosError, Swal } from '$lib/utils/alert'

  interface PurchasableItem {
    price: number
    amount: number
    amountLak: number
  }

  interface CalculateRate {
    totalAmount: number
    totalLak: number
    price: number
    purchasableItems: PurchasableItem[]
  }

  export let ccy: string = ''

  const dispatch = createEventDispatcher()
  const feePopup: PopupSettings = {
    event: 'click',
    target: 'feePopup',
    placement: 'top',
  }

  let orderType: 'MARKET' | 'LIMIT' = 'LIMIT'
  let price = 0
  let fromCcy = 'LAK'
  let fromAmount = 0
  let toCcy = ccy
  let toAmount = 0
  let purchasableItems: PurchasableItem[] = []
  let invalidExchangeMessage = ''
  let exceedReferenceRateMessage = ''
  let isExchangePanelVisible = true
  let direction = 'BUY'
  let fee = 0
  let referenceRate = 0
  let minRate = 0
  let maxRate = 0

  let description = ''
  let descriptionGroups = [
    {
      name: 'ນຳໃຊ້ຢູ່ລາວ',
      items: [
        '10001 ຄ່າສິນຄ້າ',
        '10002 ຄ່າບໍລິການ',
        '10201 ຊື້ ຫຼື ເຊົ່າ ຫຼື ຜ່ອນຍານພາຫະນະ, ດິນ, ແລະ ເຮືອນ',
        '10202 ຄ່າຮຽນ',
        '10203 ຄ່າປິ່ນປົວສຸຂະພາບ',
        '10204 ຄ່າບໍລິການທີ່ຕິດພັນກັບການຮັບເໝົາກໍ່ສ້າງ',
        '10003 ຊຳລະໜີ້ເງິນກູ້ ແລະ ສິນເຊື່ອຕ່າງໆ',
        '10004 ທ້ອນອອມ',
      ],
    },
    {
      name: 'ນຳໃຊ້ຢູ່ຕ່າງປະເທດ',
      items: [
        '21000 ຄ່າສິນຄ້າ',
        '22000 ຄ່າບໍລິການ',
        '22201 ຄ່າຮຽນ',
        '22203 ຄ່າປິ່ນປົວສຸຂະພາບ',
        '22204 ຄ່າບໍລິການທີ່ຕິດພັນກັບການຮັບເໝົາກໍ່ສ້າງ',
        '22205 ທ່ອງທ່ຽວ ແລະ ເດີນທາງໄປວຽກ',
        '20003 ຊຳລະໜີ້ເງິນກູ້ ແລະ ສິນເຊື່ອຕ່າງໆ',
        '20004 ທ້ອນອອມ',
        '20005 ໂອນລາຍຮັບກັບຄືນປະເທດ',
      ],
    },
  ]

  // ================================================================================

  // Update realtime market price
  $: if (orderType === 'MARKET') {
    const fcy = fromCcy === 'LAK' ? toCcy : fromCcy
    const book: OrderBookCCY | undefined = $orderBook[fcy]

    if (book !== undefined) {
      if (purchasableItems.length > 1) {
        price = _.round(_.sumBy(purchasableItems, 'amountLak') / _.sumBy(purchasableItems, 'amount'), 0)
      } else {
        price = direction === 'BUY' ? (_.chain(book.SELL).orderBy('price', 'asc').first().value()?.price ?? 0) : (_.chain(book.BUY).orderBy('price', 'desc').first().value()?.price ?? 0)
      }
    } else {
      price = 0
    }
  }

  $: {
    const fcy = fromCcy === 'LAK' ? toCcy : fromCcy

    // ເລດທຄຕລ
    referenceRate = $portfolio?.referencerates[fcy] ?? 0

    // ອັດຕາແລກປ່ຽນຕັ້ງໄດ້ບໍ່ຫຼຸດ 7.5% ຂອງເລດ ທຄຕລ
    const minRatePercent = 7.5
    const percentageOfMinRate = (referenceRate * minRatePercent) / 100
    minRate = _.round(referenceRate - percentageOfMinRate, 2)

    // ອັດຕາແລກປ່ຽນຕັ້ງໄດ້ບໍ່ເກີນ 5% ຂອງເລດ ທຄຕລ, ປັບ 3% ເປັນ 5% ໃນວັນທີ 31/07/2024
    const maxRatePercent = 5
    const percentageOfMaxRate = (referenceRate * maxRatePercent) / 100
    maxRate = _.round(referenceRate + percentageOfMaxRate, 2)
  }

  // ================================================================================

  onMount(() => {
    handleOrderTypeChange()
  })

  function calculateMarketRate(amount: number, amountCcy: string, book: OrderBookCCY | undefined, direction: 'BUY' | 'SELL'): CalculateRate {
    // console.clear()
    console.group('calculateMarketRate')
    console.log('calculateMarketRate Input\n', JSON.stringify({ amount, amountCcy, book, direction }, null, 2))

    if (book === undefined) {
      return {
        totalAmount: 0,
        totalLak: 0,
        price: 0,
        purchasableItems: [],
      }
    }

    const sortedTable = _.orderBy(direction === 'BUY' ? book.SELL : book.BUY, 'price', direction === 'BUY' ? 'asc' : 'desc')
    console.log('sortedTable\n', JSON.stringify(sortedTable, null, 2))

    const purchasableItems = []
    let remainAmount = amount

    for (const orderBookItem: OrderBookCCYDetail of sortedTable) {
      let purchaseLak = 0
      let purchaseAmount = 0

      if (amountCcy === 'LAK') {
        purchaseLak = Math.min(orderBookItem.amount * orderBookItem.price, remainAmount)
        purchaseAmount = _.floor(purchaseLak / orderBookItem.price, 2)
        remainAmount -= purchaseLak
      } else {
        purchaseAmount = Math.min(orderBookItem.amount, remainAmount)
        purchaseLak = purchaseAmount * orderBookItem.price
        remainAmount -= purchaseAmount
      }

      if (purchaseAmount > 0) {
        purchasableItems.push({
          price: orderBookItem.price,
          amount: purchaseAmount,
          amountLak: _.round(orderBookItem.price * purchaseAmount, 2),
        })
      }
    }

    const totalAmount = _.sumBy(purchasableItems, 'amount')
    const totalLak = _.sumBy(purchasableItems, 'amountLak')
    const price = _.round(totalLak / totalAmount, 2)
    const res = {
      totalAmount,
      totalLak,
      price,
      purchasableItems,
    }

    console.info('calculateMarketRate\n', JSON.stringify(res, null, 2))
    console.groupEnd()
    return res
  }

  // function calculateMarketRateCross(amount: number, amountCcy: string, firstBook: OrderBookCCY | undefined, secondBook: OrderBookCCY | undefined, ccyPair: string, direction: 'BUY' | 'SELL'): CalculateRate {
  //     const firstCcy = ccyPair.substring(0, 3);
  //     const secondCcy = ccyPair.substring(3, 6);
  //     if (amountCcy !== firstCcy && amountCcy !== secondCcy) throw new Error("amountCcy does not match fromCcy and toCcy");
  //
  //     if (amountCcy === firstCcy){
  //         const firstResult = calculateMarketRate(amount, amountCcy, firstBook, direction);
  //         const secondResult = calculateMarketRate(firstResult.totalAmount, secondCcy, secondBook, direction === 'BUY' ? 'SELL' : 'BUY');
  //         return {
  //             totalAmount: secondResult.totalAmount,
  //             totalLak: secondResult.totalLak,
  //             price: _.round(secondResult.totalLak / secondResult.totalAmount, 2),
  //             purchasableItems: secondResult.purchasableItems,
  //         }
  //     } else {
  //         const firstResult = calculateMarketRate(amount, amountCcy, secondBook, direction === 'BUY' ? 'SELL' : 'BUY');
  //         const secondResult = calculateMarketRate(firstResult.totalAmount, firstCcy, firstBook, direction);
  //         return {
  //             totalAmount: secondResult.totalAmount,
  //             totalLak: secondResult.totalLak,
  //             price: _.round(secondResult.totalLak / secondResult.totalAmount, 2),
  //             purchasableItems: secondResult.purchasableItems,
  //         }
  //     }
  // }

  function calculateInput(inputAmount: number, textBoxPosition: 'FROM' | 'TO') {
    fromAmount = textBoxPosition === 'FROM' ? inputAmount : 0
    toAmount = textBoxPosition === 'TO' ? inputAmount : 0

    purchasableItems = []
    invalidExchangeMessage = ''

    const fcy = fromCcy === 'LAK' ? toCcy : fromCcy
    const direction = fromCcy === 'LAK' ? 'BUY' : 'SELL'
    const userAmount = textBoxPosition === 'FROM' ? fromAmount : toAmount
    const userCcy = textBoxPosition === 'FROM' ? fromCcy : toCcy

    if (orderType === 'MARKET') {
      const result = calculateMarketRate(userAmount, userCcy, $orderBook[fcy], direction)

      if ((userCcy === 'LAK' && userAmount <= result.totalLak + 0.01 * result.price) || (userCcy !== 'LAK' && userAmount <= result.totalAmount + 0.01)) {
        const oppositeAmount = userCcy === 'LAK' ? result.totalAmount : result.totalLak
        if (textBoxPosition === 'FROM') toAmount = oppositeAmount
        else fromAmount = oppositeAmount
        purchasableItems = result.purchasableItems
      } else {
        if (fromAmount > 0 || toAmount > 0) {
          invalidExchangeMessage = 'ຈຳນວນເງິນທີ່ທ່ານຕ້ອງການບໍ່ພຽງພໍໃນຕະຫຼາດ'
        }
      }
    } else if (orderType === 'LIMIT') {
      const oppositeAmount = userCcy === 'LAK' ? _.round(inputAmount / price, 2) : inputAmount * price

      if (textBoxPosition === 'FROM') toAmount = oppositeAmount
      else fromAmount = oppositeAmount
    }

    calculateFee()
  }

  function calculateFee() {
    const feeRate = $portfolio?.feerate ?? 0
    let fromAmountRounded = _.round(fromAmount, 2)

    if (fromCcy === 'LAK') fromAmountRounded = _.round(toAmount * price, 2)
    fee = _.round(fromAmountRounded * feeRate, 6)
  }

  function handleOrderTypeChange() {
    // Auto set price if user change order type to "LIMIT"
    if (orderType === 'LIMIT') {
      price = $portfolio?.referencerates[ccy] ?? 0
    }
    calculateAmount()
    // checkReferenceRate()
  }

  function calculateAmount() {
    fromAmount > 0 ? calculateInput(fromAmount, 'FROM') : calculateInput(toAmount, 'TO')
  }

  function toggleExchangePanel(clickDirection: 'BUY' | 'SELL') {
    if (direction !== clickDirection) {
      direction = clickDirection

      const _fromCcy = _.clone(fromCcy)
      const _fromAmount = _.clone(fromAmount)

      const _toCcy = _.clone(toCcy)
      const _toAmount = _.clone(toAmount)

      fromCcy = _toCcy
      toCcy = _fromCcy

      if (fromAmount > 0) fromAmount = _toAmount
      else toAmount = _fromAmount

      calculateAmount()
      // checkReferenceRate()

      isExchangePanelVisible = true
    } else {
      isExchangePanelVisible = !isExchangePanelVisible
    }
  }

  async function placeOrder() {
    // return
    // if (!isInitAccountsValid()) {
    //   showAccountSettingModal()
    //   return
    // }
    //
    // if (!$portfolio) return

    // if (!isMarketOpen($portfolio.openDay, $portfolio.opentime, $portfolio.closeday, $portfolio.closetime)) {
    //     return Swal.fire({
    //         icon: 'error',
    //         text: `ບໍລິການແລກປ່ຽນ ເປີດໃນເວລາ ${$portfolio.opentime} ຫາ ${$portfolio.closeday}, ວັນ${getDayName($portfolio.openday)} ຫາ ວັນ${getDayName($portfolio.closeday)}`
    //     })
    // }

    const _price = orderType === 'MARKET' ? (_.last(purchasableItems)?.price ?? 0) : price

    const _amount = orderType === 'MARKET' ? _.sumBy(purchasableItems, 'amount') : fromCcy === 'LAK' ? toAmount : fromAmount

    const _amountLak = orderType === 'MARKET' ? _.sumBy(purchasableItems, 'amountLak') : _.round(_amount * _price, 2)

    const params = {
      command: 'placeorder',
      orderType,
      direction,
      ccy: fromCcy === 'LAK' ? toCcy : fromCcy,
      price: _price,
      amount: _amount,
      amountLak: _amountLak,
      fee,
      description,
    }
    console.log(JSON.stringify(params, null, 2))

    // ----------------------------------------

    if (orderType === 'MARKET' && price === 0) {
      return Swal.fire({
        icon: 'error',
        text: 'ອັດຕາແລກປ່ຽນບໍ່ຖືກຕ້ອງ',
      })
    }

    if (orderType === 'LIMIT' && price === 0) {
      return Swal.fire({
        icon: 'error',
        text: 'ກະລຸນາປ້ອນອັດຕາແລກປ່ຽນ',
      })
    }

    // checkReferenceRate()
    // if (exceedReferenceRateMessage !== '') {
    //     return Swal.fire({
    //         icon: 'error',
    //         text: exceedReferenceRateMessage
    //     })
    // }

    if (fromAmount === 0 && toAmount === 0) {
      return Swal.fire({
        icon: 'error',
        text: 'ກະລຸນາປ້ອນຈຳນວນເງິນ',
      })
    }

    if (params.amount === 0 || params.amountLak === 0) {
      return Swal.fire({
        icon: 'error',
        text: 'ຈຳນວນເງິນທີ່ທ່ານຕ້ອງການບໍ່ພຽງພໍໃນຕະຫຼາດ',
      })
    }

    if (description === '') {
      return Swal.fire({
        icon: 'error',
        text: 'ກະລຸນາເລືອກຈຸດປະສົງ',
      })
    }

    const fcy = fromCcy === 'LAK' ? toCcy : fromCcy
    const fcyAmount = fromCcy === 'LAK' ? toAmount : fromAmount
    const confirmText =
      orderType === 'MARKET' && purchasableItems.length > 1
        ? `ທ່ານຕ້ອງການ${direction === 'BUY' ? 'ຊື້' : 'ຂາຍ'} ${formatNumber(fcyAmount)} ${fcy} ໃນລາຄາສະເລ່ຍ ${formatNumber(price)} ແທ້ບໍ່?`
        : `ທ່ານຕ້ອງການ${direction === 'BUY' ? 'ຊື້' : 'ຂາຍ'} ${formatNumber(fcyAmount)} ${fcy} ໃນລາຄາ ${formatNumber(price)} ແທ້ບໍ່?`

    const { isConfirmed } = await Swal.fire({
      icon: 'question',
      text: confirmText,
      confirmButtonText: 'ຢືນຢັນ',
      showCancelButton: true,
    })

    if (!isConfirmed) return

    // ----------------------------------------

    try {
      showLoading()

      await sendCoreRequest('/user/placeorder', params)
      await updateAccountBalance()

      cancelLoading()

      fromAmount = 0
      toAmount = 0
      fee = 0
      description = ''
      purchasableItems = []
      invalidExchangeMessage = ''

      dispatch('placeOrderSuccess')
    } catch (error) {
      await handleAxiosError(error)
    }
  }

  function checkReferenceRate() {
    if (orderType !== 'LIMIT' || price === 0) {
      exceedReferenceRateMessage = ''
      return
    }

    // console.log('checkReferenceRate', JSON.stringify({
    //     price,
    //     referenceRate,
    //     percentageOfReferenceRate,
    //     between: `${lowerLimit} to ${higherLimit}`,
    // }, null, 2))

    if (price < minRate) {
      exceedReferenceRateMessage = `ອັດຕາແລກປ່ຽນຕ້ອງບໍ່ຫຼຸດ ${formatNumber(minRate)}`
    } else if (price > maxRate) {
      exceedReferenceRateMessage = `ອັດຕາແລກປ່ຽນຕ້ອງບໍ່ເກີນ ${formatNumber(maxRate)}`
    } else {
      exceedReferenceRateMessage = ''
    }
  }

  function handlePriceInput(value: number) {
    price = value
    calculateAmount()
    // checkReferenceRate()
  }
</script>

<section class="m-4 space-y-4 {$$restProps.class}">
  <nav class="flex items-center space-x-1">
    <button type="button" class="flex w-full flex-1 items-center border-b-4 p-2 text-white {direction === 'BUY' ? 'border-primary' : 'border-gray-600'}" on:click={() => toggleExchangePanel('BUY')}>
      <span class="flex-1 font-bold">ຊື້</span>
    </button>

    <button type="button" class="flex w-full flex-1 items-center border-b-4 p-2 text-white {direction === 'SELL' ? 'border-primary' : 'border-gray-600'}" on:click={() => toggleExchangePanel('SELL')}>
      <span class="flex-1 font-bold">ຂາຍ</span>
    </button>
  </nav>

  {#if isExchangePanelVisible}
    <main class="card bg-[#2A2A2D] p-4" style="background-color: #2A2A2D;">
      <div class="mb-4 space-y-2">
        <div class="flex items-center space-x-2">
          <!--          <RadioGroup spacing="space-x-1" padding="py-1.5 px-2" background="bg-[#424242]" active="bg-b1-red text-white" class="items-center justify-center">-->
          <!--            <RadioItem bind:group={orderType} name="ccy" value="MARKET" class="text-sm whitespace-nowrap" on:change={handleOrderTypeChange}>ຕາມຕະຫຼາດ</RadioItem>-->
          <!--            <RadioItem bind:group={orderType} name="ccy" value="LIMIT" class="text-sm whitespace-nowrap" on:change={handleOrderTypeChange}>ກຳນົດເອງ</RadioItem>-->
          <!--          </RadioGroup>-->
          <div class="input-group input-group-divider grid-cols-[auto_1fr_auto]">
            <div class="input-group-shim w-28 !justify-center bg-gray-100 text-center font-bold leading-[1.1]">{direction === 'BUY' ? 'ລາຄາຊື້' : 'ລາຄາຂາຍ'}</div>
            <InputMask
              class="input rounded-l-none text-center text-gray-600"
              inputmode="decimal"
              autocapitalize="off"
              autocomplete="off"
              spellcheck="false"
              placeholder="ປ້ອນອັດຕາແລກປ່ຽນ"
              value={price}
              option={priceMaskOption}
              disabled={orderType === 'MARKET'}
              on:input={({ detail }) => handlePriceInput(detail)} />
          </div>
        </div>

        {#if exceedReferenceRateMessage !== ''}
          <div class="m-4 mb-0 text-center text-sm text-error-400">{exceedReferenceRateMessage}</div>
        {/if}
      </div>

      <div class="space-y-4">
        <div class="input-group input-group-divider grid-cols-[auto_1fr_auto]">
          <div class="input-group-shim w-28 !justify-center bg-gray-100 font-bold">{fromCcy}</div>
          <InputMask
            class="input rounded-l-none text-center text-gray-600"
            inputmode="decimal"
            autocapitalize="off"
            autocomplete="off"
            spellcheck="false"
            placeholder="ປ້ອນຈຳນວນ"
            value={fromAmount}
            option={exchangeAmountMaskOption}
            on:input={({ detail }) => calculateInput(detail, 'FROM')} />
        </div>

        <div class="input-group input-group-divider grid-cols-[auto_1fr_auto]">
          <div class="input-group-shim w-28 !justify-center bg-gray-100 font-bold">{toCcy}</div>
          <InputMask
            class="input rounded-l-none text-center text-gray-600"
            inputmode="decimal"
            autocapitalize="off"
            autocomplete="off"
            spellcheck="false"
            placeholder="ປ້ອນຈຳນວນ"
            value={toAmount}
            option={exchangeAmountMaskOption}
            on:input={({ detail }) => calculateInput(detail, 'TO')} />
        </div>

        <div class="space-y-2">
          <div class="text-center text-sm font-bold">ຈຸດປະສົງ</div>
          <select class="input" bind:value={description}>
            <option value="" readonly>ເລືອກຈຸດປະສົງ</option>

            {#each descriptionGroups as descriptionGroup}
              <optgroup label={descriptionGroup.name}>
                {#each descriptionGroup.items as item}
                  <option value={item}>{item}</option>
                {/each}
              </optgroup>
            {/each}
          </select>
        </div>

        {#if fee > 0}
          <div class="flex items-center">
            <div class="mr-3 text-sm font-medium">ຄ່າທຳນຽມ: {formatNumber(fee, 6)} {fromCcy}</div>
            <div use:popup={feePopup}>
              <Icon icon="mdi:information-slab-circle-outline" class="h-4 w-4" />
            </div>
            <div data-popup="feePopup">
              <div class="card relative border border-main-black p-2 text-center text-xs drop-shadow-lg">
                ຄ່າທຳນຽມຈຳນວນ {($portfolio?.feerate ?? 0) * 100}%
                <br />
                ຈະເກັບທັງຜູ້ຊື້ ແລະ ຜູ້ຂາຍ
              </div>
              <div class="arrow border-b border-r border-main-black bg-white"></div>
            </div>
          </div>
        {/if}
      </div>

      {#if purchasableItems.length > 0}
        {@const srcCCY = fromCcy === 'LAK' ? toCcy : fromCcy}
        {@const dstCCY = fromCcy === 'LAK' ? fromCcy : toCcy}

        <div class="my-8 space-y-4">
          <div class="text-center font-bold">ການແລກປ່ຽນຕົວຈິງ</div>
          <div class="table-container rounded-none text-sm">
            <table class="table rounded-none bg-white">
              <thead>
                <tr>
                  <th class="bg-gray-50 !p-2 text-right !text-sm">ອັດຕາແລກປ່ຽນ</th>
                  <th class="bg-gray-50 !p-2 text-right !text-sm">ແລກໄດ້</th>
                  <th class="bg-gray-50 !p-2 text-right !text-sm">ເປັນເງິນ</th>
                </tr>
              </thead>
              <tbody>
                {#each purchasableItems as item}
                  <tr>
                    <td class="!bg-white !p-2 text-right !text-sm">{formatNumber(item.price)}</td>
                    <td class="!bg-white !p-2 text-right !text-sm">{formatNumber(item.amount)} {srcCCY}</td>
                    <td class="!bg-white !p-2 text-right !text-sm">{formatNumber(item.amountLak)} {dstCCY}</td>
                  </tr>
                {/each}

                <tr>
                  <td class="!p-2 text-right !text-sm font-bold">ລວມ:</td>
                  <td class="!p-2 text-right !text-sm font-bold">{formatNumber(_.sumBy(purchasableItems, 'amount'))} {srcCCY}</td>
                  <td class="!p-2 text-right !text-sm font-bold">{formatNumber(_.sumBy(purchasableItems, 'amountLak'))} {dstCCY}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      {/if}

      {#if invalidExchangeMessage !== ''}
        <div class="m-4 mb-0 text-center text-sm text-error-400">{invalidExchangeMessage}</div>
      {/if}

      <button type="button" class="btn mt-8 block w-full py-3 font-bold text-white {direction === 'BUY' ? 'bg-buy' : 'bg-sell'}" on:click={placeOrder}>
        {direction === 'BUY' ? 'ຢືນຢັນຊື້' : 'ຢືນຢັນຂາຍ'}
      </button>
    </main>
  {/if}
</section>
