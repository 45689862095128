<div class="rounded-full border border-gray-50 bg-white p-2 shadow-lg">
  <div class="loader"></div>
</div>

<style>
  .loader {
    @apply w-14 rounded-full bg-green-700 p-2;

    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    aspect-ratio: 1;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }

  @keyframes l3 {
    to {
      transform: rotate(1turn);
    }
  }
</style>
