<script lang="ts">
  import { isLoadingBalance, portfolio } from '$lib/store'
  import { formatNumber, getCcySymbol } from '$lib/utils'
  import { getContext, onMount } from 'svelte'
  import type { PageContext } from '$lib/definitions'
  import ProfileCard from '$lib/components/ProfileCard.svelte'
  import Icon from '@iconify/svelte'
  import { updateAccountBalance } from '$lib/utils/app'
  import { portal } from 'svelte-portal'
  import AccountBalanceCard from '$lib/components/AccountBalanceCard.svelte'

  const { setPage } = getContext<PageContext>('pageContext')

  onMount(() => {
    setPage({
      navbarTitle: 'ບັນຊີ',
      showAccountBalance: true,
    })
  })
</script>

<svelte:head>
  <title>ບັນຊີ</title>
</svelte:head>

<header class="flex flex-col px-4 py-2 md:flex-row md:items-end md:justify-between md:space-x-4" use:portal={'#navbar-portal'}>
  <ProfileCard class="order-1 mt-2 hidden md:order-1 md:mt-0 md:flex" />
  <AccountBalanceCard class="md:order-2 md:col-span-3 md:block" />
</header>

<button type="button" class="-mr-4" on:click={updateAccountBalance} use:portal={'#navbar-context-portal'}>
  <Icon icon="mdi:refresh" class="size-7 text-white" />
</button>

<div class="m-4 space-y-4">
  <ProfileCard class="md:hidden" />

  {#if $isLoadingBalance}
    <aside class="space-y-2">
      {#each Array(4) as _}
        <div class="card">
          <div class="flex items-center space-x-3 p-3">
            <div class="flex-1 space-y-3">
              <div class="placeholder animate-pulse"></div>
              <div class="placeholder animate-pulse"></div>
            </div>
          </div>
        </div>
      {/each}
    </aside>
  {/if}

  {#if $portfolio}
    <section class="divide-y divide-main-black/30 overflow-hidden rounded-xl">
      {#each $portfolio.accounts as account}
        <div class="flex space-x-3 bg-[#2A2A2D] p-3 text-sm">
          <img src="/images/ccy-{account.ccy.toLocaleLowerCase()}-rounded.svg" class="size-8" />

          <div class="flex-1 space-y-2 md:flex md:space-x-16 md:space-y-0">
            <div class="space-y-0.5">
              <div class="text-xs font-light text-gray-300">ບັນຊີ LFX</div>
              <div class="select-text text-xs">{account.account}</div>
            </div>
            <div class="space-y-0.5">
              <div class="text-xs font-light text-gray-300">ບັນຊີທະນາຄານ</div>
              <div class="select-text text-xs">{account.linkaccount ? account.linkaccount : 'ບໍ່ໄດ້ຜູກບັນຊີທະນາຄານ'}</div>
            </div>
          </div>

          <div class="font-bold">{getCcySymbol(account.ccy)} {formatNumber(account.availablebalance, 2)}</div>
        </div>
      {/each}
    </section>
  {/if}
</div>

<!--<section class="card p-4 m-4 space-x-4 flex items-center">-->
<!--    <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.634 0.666992C14.6913 0.666992 14.7474 0.671814 14.8021 0.681073L14.9845 0.682192C15.2565 0.682192 15.5165 0.792859 15.7059 0.988859L22.4592 8.02486C22.6379 8.21019 22.738 8.45953 22.738 8.71686V20.9382C22.7619 24.2835 20.1565 27.0169 16.8059 27.1529L6.11387 27.1542H5.96854C2.70195 27.0804 0.0820858 24.4385 0.00227164 21.2038L0.0018692 6.65419C0.0792025 3.34619 2.8112 0.682192 6.0952 0.682192L14.4659 0.681073C14.5206 0.671814 14.5767 0.666992 14.634 0.666992ZM13.6339 2.68166L6.09787 2.68219C3.88854 2.68219 2.05387 4.47153 2.00187 6.67819V20.9382C1.95254 23.2222 3.75254 25.1035 6.01387 25.1542H16.7659C18.9912 25.0622 20.7539 23.2129 20.738 20.9462L20.7379 9.97766L18.058 9.97899C15.618 9.97233 13.634 7.98299 13.634 5.54566L13.6339 2.68166ZM14.3849 17.4778C14.9369 17.4778 15.3849 17.9258 15.3849 18.4778C15.3849 19.0298 14.9369 19.4778 14.3849 19.4778H7.18494C6.63294 19.4778 6.18494 19.0298 6.18494 18.4778C6.18494 17.9258 6.63294 17.4778 7.18494 17.4778H14.3849ZM11.6584 12.4751C12.2104 12.4751 12.6584 12.9231 12.6584 13.4751C12.6584 14.0271 12.2104 14.4751 11.6584 14.4751H7.18374C6.63174 14.4751 6.18374 14.0271 6.18374 13.4751C6.18374 12.9231 6.63174 12.4751 7.18374 12.4751H11.6584ZM15.6339 3.80299L15.634 5.54566C15.634 6.88433 16.7233 7.97499 18.0607 7.97899L19.6419 7.97766L15.6339 3.80299Z" fill="#B71C1C"/>-->
<!--    </svg>-->
<!--    <div class="flex-1">ເງື່ອນໄຂ ແລະ ຂໍ້ກຳນົດ</div>-->
<!--</section>-->
